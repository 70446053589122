import React from "react"
import Index from "@pages/pamm.js";

const PammJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default PammJa
